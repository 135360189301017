<template>
    <div>
        <custom-vue-table-card class="d-sm-none-lg-block" title="CONTACT US ENQUIRIES" :fields="fields" :url="listUrl" :per-page="10" ref="table">

            <template #request_call_back="{rowData}">
                <span v-if="rowData.request_call_back">Yes</span>
                <span v-else>No</span>
            </template>

            <template #footer>
                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete the enquiry. Are you sure ?</p>

                    <template #loading>
                        <loading-animation/>
                        Please wait while we enquiry.
                    </template>
                </delete-modal>
            </template>

        </custom-vue-table-card>
        <enquiries-mobile-view class="d-lg-none"></enquiries-mobile-view>
    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import EnquiriesMobileView from '../../../../views/cms/contact-us/enquiries/EnquiriesMobileView';

export default {
    name       : 'Enquiries',
    components : { EnquiriesMobileView },
    data () {
        return {
            listUrl      : urls.enquiries.list,
            deleteUrl    : urls.enquiries.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'mobile_number',
                    sortField : 'mobile_number',
                    title     : 'Phone'
                },
                {
                    name      : 'email_id',
                    sortField : 'email_id',
                    title     : 'E-Mail'
                },
                {
                    name      : 'district',
                    sortField : 'district',
                    title     : 'District'
                },
                {
                    name      : 'subject',
                    sortField : 'subject',
                    title     : 'Subject'
                },
                {
                    name      : 'message',
                    sortField : 'message',
                    title     : 'Message'
                },
                {
                    name      : '__slot:request_call_back',
                    sortField : 'request_call_back',
                    title     : 'Request Call Back'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            this.$emit('refresh');
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$emit('refresh');
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
