<template>
    <div class="d-lg-none py-4">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>
            <h4 class="my-3">CONTACT US ENQUIRIES</h4>
            <s-form>
                <validated-input placeholder="Search" v-model="searchParam"></validated-input>
            </s-form>
            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-2">
                <div class="mb-5">
                    <div class="gr-3-cols b-1 p-2">

                        <div class="fl-gr-1">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r">
                                    <!--                                    <div class="line-right-1"></div>-->
                                    <span class="font-inter-medium">ID</span>
                                </div>
                            </div>
                        </div>

                        <div class="fl-gr-1">
                            <div class="fl-x fl-j-s fl-a-c">
                                <div class="pos-r">
                                    <!--                                    <div class="line-right-1"></div>-->
                                    <span class="font-inter-medium">Name</span>
                                </div>
                            </div>
                        </div>

                        <div class="fl-gr-1">
                            <div class="fl-x fl-j-s fl-a-c">
                                <div class="pos-r text-left">
                                    <!--                                    <div class="line-right-1"></div>-->
                                    <span class="font-inter-medium">Phone</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="b-1 bs-3">
                        <div class="gr-3-cols font-inter-medium c-line-bottom p-2">

                            <div class="">
                                <div class="fl-x fl-j-c fl-a-c">
                                    <div class="pos-r">
                                        <!--                                        <div class="line-right-1"></div>-->
                                        {{ i.id }}
                                    </div>
                                </div>
                            </div>

                            <div class="text-center">
                                <div class="pos-r text-left">
                                    <div class="line-right-1 capitalize-string"></div>
                                    {{ i.name }}
                                </div>
                            </div>

                            <div class="">
                                <div class="pos-r">
                                    <div class="line-right-1"></div>
                                    {{ i.mobile_number }}
                                </div>
                            </div>

                        </div>

                        <div class="row b-1 fs--1 m-0">
                        <span v-if="i.email_id" class="b-1 w-100 col-6 p-2">
                            <div class="p-1">
                                <span class="font-inter-medium">Email : </span> <span class="ml-2">
                                    {{ i.email_id }}
                                </span>
                            </div>
                        </span>

                            <span v-if="i.district" class="b-1 w-100 col-6 p-2">
                            <div class="p-1">
                               <span class="font-inter-medium">District</span> <span class="ml-2">{{ i.district }}</span>
                            </div>
                        </span>
                            <span v-if="i.subject" class="b-1 w-100 col-6 p-2">
                            <div class="p-1">
                               <span class="font-inter-medium">Subject</span> <span class="ml-2">{{ i.subject }}</span>
                            </div>
                        </span>
                            <span v-if="i.message" class="b-1 w-100 col-6 p-2">
                            <div class="p-1">
                               <span class="font-inter-medium">Message</span> <span class="ml-2">{{ i.message }}</span>
                            </div>
                        </span>
                            <span class="b-1 w-100 col-6 p-2">
                            <div class="p-1">
                               <span class="font-inter-medium">Request Call Back</span>
                                <span class="ml-2" v-if="i.request_call_back">YES</span>
                                <span class="ml-2" v-else>NO</span>
                            </div>
                        </span>
                            <span class="b-1 col-12">
<!--                    <div class="p-1 fl-x fl-j-c py-3">-->
                                <!--                        <edit-btn @click="viewDetails(i.order_id)" size="sm" class="px-4" icon="" text="Details"/>-->
                                <!--                    </div>-->
                </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../../../data/cms-urls';
import _ from 'lodash';

export default {
    name : 'EnquiriesMobileView',

    data () {
        return {
            prev        : '',
            next        : '',
            page        : '1',
            per_page    : '5',
            loading     : true,
            error       : false,
            details     : null,
            searchParam : ''

        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    watch : {
        searchParam : _.debounce(function (newVal) {
            this.searchInput();
        }, 500)
    },

    mounted () {
        this.loadData();
    },

    methods : {
        viewDetails (item) {
            this.$router.push('/transactions/' + item + '/details/');
        },
        async loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            await axios.get(urls.enquiries.list, {
                params : {
                    page     : that.page,
                    per_page : that.per_page
                }
            }).then(function (response) {
                console.log('response-enquiry', response);
                const json = response.data;
                that.details = { ...json };
                that.setPagination();
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },
        async searchInput () {
            // alert('search input');
            const that = this;

            that.loading = true;
            that.error = false;

            await axios.get(urls.enquiries.list, {
                params : {
                    page     : that.page,
                    per_page : that.per_page,
                    search   : that.searchParam
                }
            }).then(function (response) {
                console.log('response-enquiry', response);
                const json = response.data;
                that.details = { ...json };
                that.setPagination();
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        }

    }
};
</script>

<style scoped>

</style>
